import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import { fetchSinToken } from '../helpers/fetch';

import { AuthContext } from '../reducers/auth/AuthContext';
import { CompanyContext } from '../reducers/CompanyContext';
import { CompanyReducer } from '../reducers/CompanyReducer';
import { SectionContext } from '../reducers/sections/sectionsContext';
import { SectionsReducer } from '../reducers/sections/SectionsReducer';
import { types } from '../types/types';

import CompanyScreen from '../components/company/CompanyScreen';
import DashboardScreen from '../components/DashboardScreen/DashboardScreen';
import { DashboardNav } from '../components/_ui/DashboardNav/DashboardNav';
import PostsScreen from '../components/posts/PostsScreen/PostsScreen';
import AddPostScreen from '../components/posts/AddPosts/AddPostScreen';
import EditPostScreen from '../components/posts/EditPosts/EditPostScreen';
import { SectionScreen } from '../components/sections/SectionScreen';
import { AddSection } from '../components/sections/AddSection';
import LoginScreen from '../components/auth/LoginScreen/LoginScreen';
import AllSections from '../components/sections/AllSections';
import MessagesDashboard from '../components/MessagesScreen/MessagesDashboard';
import Message from '../components/MessagesScreen/Message/Message';

const AppRouter = () => {
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const [company, dispatchCompany] = useReducer(CompanyReducer, {});
	const [sections, dispatchSections] = useReducer(SectionsReducer, {});

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	});

	useEffect(() => {
		if (isMounted.current) {
			fetchSinToken(`sections`)
				.then((data) => data.json())
				.then((data) => dispatchSections({ type: types.getSections, payload: data.sections }))
				.catch((err) => new Error(err));
			fetchSinToken(`companies`)
				.then((data) => data.json())
				.then((data) => dispatchCompany({ type: types.getCompany, payload: data.company[0] }))
				.catch((err) => new Error(err));
		}
	}, []);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);


	return (
		<Router>
			<div>
				{ user.token ? (
					<CompanyContext.Provider value={ { company, dispatchCompany } }>
						<SectionContext.Provider value={ { sections, dispatchSections } }>

							<div className='dashboard-container'>
								<DashboardNav handleShow={ handleShow } />

								<main>
									<Switch>
										<Route exact path='/barriozubieta' component={ DashboardScreen } />
										<Route exact path='/barriozubieta/empresa' component={ CompanyScreen } />
										<Route exact path='/barriozubieta/blog' component={ PostsScreen } />
										<Route exact path='/barriozubieta/blog/nueva-entrada/:postID' component={ AddPostScreen } />
										<Route exact path='/barriozubieta/blog/editar-entrada/:postID' component={ EditPostScreen } />
										<Route exact path='/barriozubieta/mensajes' component={ MessagesDashboard } />
										<Route exact path='/barriozubieta/mensajes/ver/:msgID' component={ Message } />
										<Route exact path='/barriozubieta/seccion' component={ AllSections } />
										<Route exact path='/barriozubieta/seccion/:id' component={ (props) => <SectionScreen { ...props } /> } />
										<Redirect to='/barriozubieta' />
									</Switch>

									<Modal size='xl' centered show={ show } onHide={ handleClose }>
										<Modal.Header closeButton>
											<h4>Añadir Sección a la web</h4>
										</Modal.Header>
										<Modal.Body>
											<AddSection handleClose={ handleClose } />
										</Modal.Body>
									</Modal>
								</main>
							</div>
						</SectionContext.Provider>
					</CompanyContext.Provider>
				) : (
					<section>
						<main className='login-container'>
							<figure>
								<img src={ process.env.REACT_APP_COMPANY_LOGO } alt="" />
							</figure>
							<Switch>
								<Route exact path='/' component={ LoginScreen } />
								<Redirect to='/' />
							</Switch>
							<div className="copyright">
								<small>&copy; Barrio & Zubieta S.L.</small>
							</div>
						</main>
					</section>
				) }
			</div>
		</Router>
	);
};

export default AppRouter;
